import * as React from "react";
import { motion } from "framer-motion";
import Seo from "../Seo";
import CursorProvider from "../CursorProvider";

const PageWrapper = ({ seoTitle, seoDescription, children }) => {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Seo title={seoTitle} description={seoDescription} />
      <CursorProvider />
      {children}
    </motion.main>
  );
};

export default PageWrapper;
