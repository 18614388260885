import * as React from "react";
import * as style from "./headerSection.module.scss";
import BoundedSection from "../BoundedSection/";

const TrileucoOrbitBalls = () => {
  const TrileucoBall = () => <li className={style.TrileucoBall} />;
  return (
    <ul className={style.TrileucoBalls}>
      <TrileucoBall />
      <TrileucoBall />
      <TrileucoBall />
    </ul>
  );
};

const HeaderSection = ({
  preTitle,
  title,
  description,
  className,
  disableOrbitBalls,
}) => {
  return (
    <BoundedSection
      className={`${style.HeaderSection} ${className ? className : ""}`.trim()}
    >
      {preTitle && <h2 className={style.HeaderSection_preTitle}>{preTitle}</h2>}
      <h1 className={style.HeaderSection_title}>{title}</h1>
      {description && (
        <div className={style.HeaderSection_description}>{description}</div>
      )}
      {!disableOrbitBalls && <TrileucoOrbitBalls />}
    </BoundedSection>
  );
};

export default HeaderSection;
