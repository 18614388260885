import * as React from "react";
import * as style from "./styles/legal.module.scss";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection/";
import BoundedSection from "components/BoundedSection";

const LegalPage = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("privacyPolicy.seo.title")}
      seoDescription={t("privacyPolicy.seo.description")}
    >
      <HeaderSection
        title={t("privacyPolicy.header.title")}
        description={<Trans i18nKey="privacyPolicy.header.description" />}
      />
      <BoundedSection className={style.LegalInfo}>
        <p>Gracias por visitar este sitio web.</p>
        <p>
          Lee detenidamente los Términos y Condiciones que constan en este
          documento, ya que el uso de este sitio web implica la expresa y plena
          aceptación de los mismos, en la versión publicada en el momento en que
          accedes al mismo. Le recomendamos que lea atentamente este documento
          cada vez que acceda al sitio web para comprobar si ha habido cambios
          en las condiciones de uso y lo abandone si no está de acuerdo con
          dichos cambios. Si creemos que ciertas modificaciones son importantes,
          actualizaremos la fecha de "Última modificación" en la parte superior
          de esta página. Usted será el responsable de revisar y familiarizarse
          con cualquier modificación realizada.
        </p>
        <h3>
          <span>01.</span>Identificación
        </h3>
        <p>
          En cumplimiento con el deber de información recogido en artículo 10 de
          la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
          Información y de Comercio Electrónico con la legislación vigente en
          materia de protección de datos, en concreto, la Ley orgánica 3/2018,
          de 5 de diciembre, de protección de datos personales y garantía de los
          derechos digitales (LOPDDGG) que hace plenamente efectiva la
          aplicación del Reglamento (UE) 2016/679 del Parlamento Europeo y del
          Consejo de 27 de abril de 2016 relativo a la protección de las
          personas físicas en el que respeta al tratamiento de datos personales
          y a la libre circulación de estos datos (RGPD).
        </p>
        <p>
          <strong>Responsable del Tratamiento:</strong> Trileuco Solutions,
          S.L.U. (en adelante LA EMPRESA)
          <br />
          <strong>Nombre Comercial:</strong> Trileuco Solutions
          <br />
          <strong>CIF:</strong> B-70233416
          <br />
          Inscrita en el Registro Mercantil de A Coruña el 11 de diciembre de
          2009, en el Tomo 3.362 del Archivo, Sección General, folio 155, hoja
          número C-45.707, inscripción 1ª, e identificada fiscalmente por el
          Ministerio de Economía y Hacienda
        </p>

        <h3>
          <span>02.</span>Comunicación
        </h3>
        <p>
          Para contactar con nosotros para cualquier cuestión que haga
          referencia al tratamiento de datos personales, ponemos a su
          disposición diferentes medios de contacto que detallamos a
          continuación:
        </p>
        <p>
          <strong>Teléfono:</strong> 881 893 736
          <br />
          <strong>Correo electrónico:</strong> info@trileucosolutions.com
          <br />
          <strong>Domicilio social:</strong> Avenida de Monelos 77-79, 8ºIZQ
          15009 A Coruña
          <br />
          <strong>Página web:</strong> https://trileucosolutions.com
        </p>
        <p>
          Todas las notificaciones y comunicaciones facilitadas en este apartado
          se considerarán eficaces, a todos los efectos, cuando se realicen a
          través de cualquier medio de los detallados anteriormente
        </p>
        <p>
          Nosotros entendemos que la privacidad y la seguridad de su información
          personal son extremadamente importantes. Por eso, esta política
          establece lo que hacemos con su información y lo que hacemos para
          mantenerla segura.  También explica dónde y cómo recopilamos su
          información personal, así como sus derechos sobre cualquier
          información personal que tengamos sobre usted.
        </p>
        <h4>¿Qué es el nuevo Reglamento de Protección de Datos?</h4>
        <p>
          Es una nueva normativa europea. Este Reglamento unifica criterios de
          confidencialidad y tratamientos de los datos personales a nivel
          comunitario y adapta su gestión a los nuevos entornos digitales, como
          internet y aplicaciones para móviles. De esta manera, usted tendrá más
          control sobre sus datos.
        </p>
        <h4>¿Cuándo será aplicable la nueva normativa?</h4>
        <p>
          La Unión Europea aprobó el Reglamento en 2016. Los Estados miembros la
          deben aplicar a partir del 25 de mayo del 2018.
        </p>
        <h4>¿Qué cambios va a suponer para usted?</h4>
        <p>
          La normativa europea añade nuevos derechos a los recogidos en la
          actual normativa española. Por este V7.0 motivo, debemos actualizar
          información a su disposición sobre las medidas de protección de datos
          nuestra empresa. También solicitaremos su autorización para poder
          tratar sus datos en algunos casos nuevos. De este modo, podremos
          ofrecerle un servicio más adaptado a sus necesidades.
        </p>
        <h4>
          ¿Qué medidas hemos tomado para asegurar la confidencialidad,
          integridad y seguridad de sus datos?
        </h4>
        <ul>
          <li>
            Solo pedimos la cantidad mínima de información necesaria,
            recopilando solo lo que creemos que es esencial para hacer negocios
            o para la transacción específica que corresponda;
          </li>
          <li>
            Hemos establecido una serie de acuerdos de confidencialidad con
            todos nuestros proveedores, personal y colaboradores;
          </li>
          <li>
            Contamos con una asesoría especializada que no solo nos asistirá de
            forma permanente en esta materia sino que además realizará controles
            periódicos para asegurar el correcto cumplimiento de esta normativa;
          </li>
          <li>
            Hemos establecido una serie de medidas de seguridad informática que
            nos protegerá de posibles ataques externos;
          </li>
          <li>
            Hemos revisado toda nuestra documentación de modo que sea la
            adecuada según lo establecido en el nuevo reglamento;
          </li>
          <li>
            Hemos evaluado el impacto que pueden causar nuestros procedimientos
            sobre la protección de sus datos personales;
          </li>
          <li>
            Hemos formado nuestro personal para que todos podamos actuar de
            forma diligente y ética, cumpliendo con todas las exigencias
            previstas en el nuevo reglamento.
          </li>
        </ul>
        <h4>Principios que aplicaremos a tu información personal</h4>
        <p>
          En el tratamiento de tus datos personales, aplicaremos los siguientes
          principios que se ajustan a las exigencias del nuevo reglamento
          europeo de protección de datos:
        </p>
        <ul>
          <li>
            <strong>Principio de licitud, lealtad y transparencia:</strong>
             Siempre vamos a requerir tu consentimiento para el tratamiento de
            tus datos personales para uno o varios fines específicos que te
            informaremos previamente con absoluta transparencia.
          </li>
          <li>
            <strong>Principio de minimización de datos:</strong> Solo vamos a
            solicitar datos estrictamente necesarios en relación con los fines
            para los que los requerimos. Los mínimos posibles.
          </li>
          <li>
            <strong>Principio de limitación del plazo de conservación:</strong>{" "}
            los datos serán mantenidos durante no más tiempo del necesario para
            los fines del tratamiento, en función a la finalidad, te
            informaremos del plazo de conservación correspondiente, en el caso
            de suscripciones, periódicamente revisaremos nuestras listas y
            eliminaremos aquellos registros inactivos durante un tiempo
            considerable.
          </li>
          <li>
            <strong>Principio de integridad y confidencialidad:</strong> Tus
            datos serán tratados de tal manera que se garantice una seguridad
            adecuada de los datos personales y se garantice confidencialidad.
            Debes saber que tomamos todas las precauciones necesarias para
            evitar el acceso no autorizado o uso indebido de los datos de
            nuestros usuarios por parte de terceros.
          </li>
        </ul>

        <h3>Bases jurídicas para la recopilación y uso de la información</h3>
        <p>
          Si usted es un individuo en el Espacio Económico Europeo (EEE),
          nuestra base jurídica para la recolección y el uso de información
          depende de la información personal en cuestión y del contexto en el
          que la recopilamos. La mayoría de nuestras actividades de recopilación
          y procesamiento de información generalmente se basan en: 1) una
          necesidad contractual; 2) uno o varios intereses legítimos de LA
          EMPRESA o un tercero que no se anulen por sus intereses de protección
          de datos o 3) su consentimiento. A veces, tendremos la obligación
          legal de recopilar su información o necesitaremos su información
          personal para proteger sus intereses vitales o los de otra persona.
        </p>

        <h3>Tratamientos</h3>
        <p>
          Le explicaremos, a continuación, cómo recogemos, utilizamos,
          divulgamos, transferimos y almacenamos su información. La presente
          Política de privacidad es aplicable a la información de carácter
          personal recogida a través de nuestro sitio web. Es importante que
          consulte con frecuencia la Política de privacidad por si se ha
          actualizado.
        </p>
        <p>
          Todos los usuarios que acceden a nuestra página web podrán conocer
          todo su contenido sin la necesidad de facilitar ninguna información de
          carácter personal.  Sus datos personales solo serán recabados cuando
          usted cumplimente voluntariamente nuestro(s) formulario(s). En este
          caso, el usuario garantiza la autenticidad, exactitud y veracidad de
          la información facilitada, comprometiéndose a mantener actualizados
          los datos de carácter personal de forma que los mismos respondan, en
          todo momento, a su situación real. El usuario será el único
          responsable de las manifestaciones falsas o inexactas y de los
          perjuicios que las mismas pudieran causar. Mediante esta vía de
          comunicación usted acepta de forma expresa recibir comunicaciones
          periódicas únicamente de la entidad, que guardará los datos personales
          que reciba de los usuarios a través de la página web en total secreto,
          garantizando su confidencialidad, y adoptará las medidas técnicas
          necesarias para evitar cualquier alteración, pérdida, mal uso, o
          acceso no autorizado a estos datos.
        </p>
        <p>
          Asimismo, le comunicamos que todos los datos facilitados a través de
          formularios electrónicos y/o mediante correo electrónico son los
          estrictamente necesarios para la correcta identificación del
          remitente. Esta información será tratada con estricta confidencialidad
          y con la única finalidad de realizar la gestión de solicitudes de
          información, contratación de nuestros servicios y productos y otras
          finalidades que están especificadas a continuación. Usted queda
          informado y presta su pleno consentimiento expreso para utilizar sus
          datos para las actividades relacionadas al objeto social de la
          entidad.
        </p>
        <p>
          El consentimiento prestado, tanto para el tratamiento como para la
          cesión de los datos de los interesados, es revocable en cualquier
          momento comunicándolo a la dirección de correo electrónico
          info@trileucosolutions.com en los términos establecidos en esta
          Política para el ejercicio de los derechos. Esta revocación en ningún
          caso tendrá carácter retroactivo.
        </p>
        <h4>Cómo utilizamos sus datos</h4>
        <p>
          Podemos utilizar sus datos personales de la siguiente manera: la
          información que usted nos proporciones nos podrá ayudar en la toma de
          decisiones, a responder solicitudes, mejorar los servicios, detectar
          nuevas necesidades, generar promociones, entender sus expectativas y
          proporcionarle un mejor servicio. También podremos utilizar sus datos
          para las siguientes gestiones.
        </p>
        <h4>Procesar su pedido y proporcionarle sus productos y servicios</h4>
        <ul>
          <li>
            Procesar el pedido de los productos y servicios que nos ha comprado
            y mantenerle informado de su progreso;
          </li>
          <li>Proporcionarle el producto o servicio correspondiente.</li>
        </ul>
        <h4>Facturación y Atención al Cliente</h4>
        <ul>
          <li>
            Para facturarle o cobrarle por el uso de nuestros productos y
            servicios;
          </li>
          <li>
            Contactarle si la información de facturación que nos proporcionaste
            no está actualizada, va a caducar o no podemos aceptar el pago;
          </li>
          <li>
            Responder a cualquier pregunta o inquietud que pueda tener sobre
            nuestros, productos o servicios
          </li>
        </ul>
        <h4>Mensajes de información de servicio</h4>
        <ul>
          <li>
            Nos pondremos en contacto usted para mantenerle actualizado con
            información sobre los productos y servicios que tiene con nosotros.
          </li>
        </ul>
        <h4>Otras gestiones:</h4>
        <p>
          <strong>Razón específica:</strong> Si proporciona sus datos personales
          para un fin en concreto, los utilizaremos para lo relacionado con el
          fin para el cual se proporcionaron. Por ejemplo, si usted se pone en
          contacto con nosotros por correo electrónico, utilizaremos los datos
          personales que nos facilite para responder a su pregunta o solucionar
          el problema, y responderemos a la dirección de correo electrónico
          desde la que se envió el mensaje.
        </p>
        <p>
          <strong>Fines internos.</strong> Podemos usar sus datos personales
          para fines internos como, por ejemplo, para basarnos en ellos con el
          fin de mejorar el contenido y la funcionalidad de los servicios,
          comprender mejor la necesidad de nuestros clientes, mejorar los
          servicios, proteger, identificar o abordar actividades fraudulentas,
          reforzar nuestros términos de servicio, gestionar su cuenta y
          proporcionarle servicio de atención al cliente y, de manera general,
          gestionar los servicios y nuestra actividad comercial, entre otros.
        </p>
        <p>
          <strong>Comunicaciones comerciales.</strong> Siempre que contemos con
          su consentimiento expreso (que se obtendrá mediante una casilla
          exclusiva presente en nuestros formularios), podremos usar sus datos
          personales para ponernos en contacto con usted en el futuro para la
          realización de acciones comerciales que puedan ser de su interés,
          siempre relacionadas con los productos y/o servicios que ofrece la
          empresa. En cualquier caso, usted siempre tendrá disponible la opción
          "dejar de recibir" estos mensajes electrónicos en la parte inferior de
          dichos mensajes o notificarlo a nosotros enviando un correo
          electrónico a la siguiente dirección: info@trileucosolutions.com. Sin
          embargo, usted podrá seguir recibiendo avisos y correos electrónicos
          siempre que sean necesarios y esenciales para el mantenimiento de
          nuestras transacciones contractuales. De acuerdo con la Ley 34/2002,
          de 11 de julio, de servicios de la sociedad de la información y de
          comercio electrónico. (LSSICE) LA EMPRESA no realiza prácticas de SPAM
          y se compromete a no enviar comunicaciones de carácter comercial sin
          identificarlas debidamente.
        </p>
        <p>
          Le rogamos tenga en cuenta que aunque decida no suscribirse o se dé de
          baja de las comunicaciones electrónicas promocionales o comerciales
          puede que LA EMPRESA siga necesitando contactar con usted como Usuario
          con información importante sobre transacciones relativas a su cuenta y
          a sus compras de productos, reserva de actividades o contratación de
          otros servicios.
        </p>
        <p>
          A continuación, explicamos de forma más detallada qué tipos de datos
          se recogen y se tratan y cuáles son sus finalidades:
        </p>
        <table>
          <thead>
            <tr>
              <th>Categoría</th>
              <th>Finalidad</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Usuario visitante</td>
              <td>
                Análisis de usabilidad y de calidad para la mejora de nuestros
                servicios
              </td>
            </tr>
            <tr>
              <td>Usuario que nos contacta</td>
              <td>
                Responder a los requerimientos del usuario; dar respuesta a las
                dudas, quejas, comentarios o inquietudes que pueda tener
                relativas a la información incluida en la web, los servicios que
                se prestan a través de la web, el tratamiento de los datos
                personales, cuestiones referentes a los textos legales incluidos
                en la web, así como cualesquiera otras consultas que puedas
                tener y que no estén sujetas a las condiciones de contratación.
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Las bases legales se enlazan con las finalidades del punto anterior
        </p>
        <table>
          <thead>
            <tr>
              <th>Categoría</th>
              <th>Base legitimadora</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Usuario visitante</td>
              <td>
                El consentimiento prestado al aceptar las cookies o al seguir
                navegando en nuestra página web. (GDPR Art. 6.1.a)
              </td>
            </tr>
            <tr>
              <td>Usuario que nos contacta</td>
              <td>
                Nuestro interés legítimo en atender las consultas y solicitudes
                del interesado, justificado por el interés mostrado en contactar
                y recibir información a una mínima intromisión en su privacidad
                y la utilización de datos limitados y facilitados por el propio
                usuario. (GDPR Art. 6.1.a)
              </td>
            </tr>
          </tbody>
        </table>
        <p>Datos facilitados voluntariamente por el interesado</p>
        <table>
          <thead>
            <tr>
              <th>Categoría</th>
              <th>Datos recogidos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Usuario visitante</td>
              <td>IP, datos de navegación</td>
            </tr>
            <tr>
              <td>Usuario que nos contacta</td>
              <td>
                Datos facilitados por el interesado (normalmente: nombre,
                apellidos, correo electrónico y un número de teléfono
              </td>
            </tr>
          </tbody>
        </table>
        <p>Posibles consecuencias de no facilitar dichos datos</p>
        <table>
          <thead>
            <tr>
              <th>Categoría</th>
              <th>Consecuencias</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Usuario visitante</td>
              <td>No se recogen datos del usuario visitante</td>
            </tr>
            <tr>
              <td>Usuario que nos contacta</td>
              <td>
                No revelaremos a terceros datos de carácter personal referente a
                este tipo de usuario sin su consentimiento.
              </td>
            </tr>
          </tbody>
        </table>
        <p>Transferencias Internacionales</p>
        <table>
          <thead>
            <tr>
              <th>Categoría</th>
              <th>Transferencias internacionales</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Usuario visitante</td>
              <td>No se recogen datos del usuario visitante</td>
            </tr>
            <tr>
              <td>Usuario visitante</td>
              <td>
                No se realiza ninguna transferencia de datos personales a un
                tercer país u organización internacional
              </td>
            </tr>
          </tbody>
        </table>
        <p>Plazos de conservación</p>
        <table>
          <thead>
            <tr>
              <th>Categoría</th>
              <th>Plazos de conservación</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Usuario visitante</td>
              <td>No se almacenan datos del usuario visitante</td>
            </tr>
            <tr>
              <td>Usuario visitante</td>
              <td>
                Serán conservados el tiempo necesario para cumplir la finalidad
                de su recogida.
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Otros aspectos relacionados con la cesión de datos</h3>
        <p>
          Por regla general, los datos que usted nos facilita no son revelados a
          terceros sin su consentimiento, salvo obligación legal, por ejemplo:
          en caso de una citación judicial o una solicitud de un organismo
          gubernamental, o si creemos de buena fe que dicha acción es necesaria
          para a) para cumplir con una obligación jurídica; b) para proteger o
          defender nuestros derechos, intereses o propiedades, o los de un
          tercero; c) para prevenir o investigar ilícitos potenciales en
          relación con los Servicios; d) para actuar en circunstancias de
          urgencia para proteger su seguridad personal; o e) para resguardar
          contra responsabilidades jurídicas.
        </p>

        <h3>Almacenamiento</h3>
        <p>
          Podemos almacenar sus datos o transferirlos a un tercero que los
          almacenará de conformidad con esta Política de privacidad. Adoptamos
          las medidas que consideramos razonables para proteger los datos
          personales contra pérdida, mal uso, uso no autorizado, acceso no
          autorizado, divulgación involuntaria, modificación y destrucción. Sin
          embargo, no existen redes, servidores, bases de datos ni transmisiones
          por Internet o correo electrónico completamente seguras o exentas de
          errores. En el caso de que se produzca una violación de la seguridad
          de los datos que se encuentran bajo nuestra custodia, tomaremos todas
          las medidas necesarias para mitigar sus consecuencias y notificaremos
          este hecho a la Autoridad de Control, juntamente con toda la
          información relevante para la documentación y comunicación de la
          incidencia.
        </p>

        <h3>Consentimientos</h3>
        <p>
          El consentimiento del interesado, atendiendo a lo que enuncia la
          normativa de protección de datos, se trata de una comunicación libre
          por parte del interesado por la cual acepta que se traten sus datos,
          para una finalidad concreta, bajo unas determinadas condiciones, de
          las cuales tiene que estar previamente informado.
        </p>
        <h4>¿Puede modificar o retirar sus consentimientos cuando quiera?</h4>
        <p>
          Por supuesto que sí. La información de los tratamientos que ha
          consentido estará siempre accesible. Podrá modificarla o retirarla
          siempre que quiera a través de nuestro correo electrónico. Así mismo,
          puede darse de baja de nuestra BBDD en el momento que lo desee,
          enviándonos un mail a: info@trileucosolutions.com
        </p>

        <h3>Decisiones automatizadas</h3>
        <p>
          LA EMPRESA no adopta decisión alguna basada únicamente en el
          tratamiento automatizado de sus datos.
        </p>

        <h3>Estudios estadísticos</h3>
        <p>
          LA EMPRESA no realiza estudios con fines científicos, históricos y
          estadísticos en cuyo caso se procurará la disociación del dato al
          objeto del estudio para preservar su confidencialidad.
        </p>

        <h3>Aviso de violación de datos personales o brechas de seguridad</h3>
        <p>
          La violación de datos personales supone una brecha de la seguridad de
          los sistemas de información de LA EMPRESA que provoca o puede provocar
          la destrucción, alteración, pérdida, divulgación no autorizada o
          acceso, accidental o no, a los datos personales transmitidos,
          almacenados o procesados relacionados con la prestación de nuestros
          servicios. Para el caso de que los datos personales que almacenamos
          y/o tratamos en LA EMPRESA se vean comprometidos de alguna forma,
          procederemos a notificar oportunamente a los afectados, y conforme a
          lo dispuesto en el artículo 33 del RGPD
        </p>

        <h3>Derechos de protección de datos</h3>
        <p>
          Los usuarios podrán dirigir una comunicación por escrito al domicilio
          social de LA EMPRESA o a la dirección de correo electrónico indicada
          en el encabezamiento de este Aviso Legal, incluyendo en ambos casos
          fotocopia de su DNI u otro documento identificativo similar, para
          solicitar el ejercicio de los derechos siguientes:
        </p>
        <ul>
          <li>
            <strong>Derecho de rectificación de datos personales.</strong> Usted
            tiene derecho a rectificar información guardada sobre usted si ésta
            no es precisa. Si la información que almacenamos necesita
            actualizarse, o uste cree que puede ser errónea, puede actualizarla
            cuando juzgue necesario.
          </li>
          <li>
            <strong>Derecho de acceso a datos personales.</strong> Usted tiene
            derecho a solicitar una copia de los datos personales que guardamos
            de usted.
          </li>
          <li>
            <strong>Derecho a la portabilidad de los datos.</strong> Usted
            tienes derecho a transportar los datos que nos ha proporcionado en
            ciertas circunstancias.
          </li>
          <li>
            <strong>Derecho de oposición al uso de datos personales.</strong>{" "}
            Usted tienes el derecho a oponerse al procesamiento de tu
            información personal
          </li>
          <li>
            <strong>Derecho de cancelación.</strong> Nosotros nos esforzamos en
            procesar y retener sus datos solo el tiempo que necesitemos.
            Asimismo, usted tiene el derecho a solicitar la cancelación de sus
            datos personales que tenemos guardados. Estos datos se conservarán
            bloqueados y accesibles únicamente por determinadas personas para el
            caso de que los necesitemos para atender alguna reclamación o
            responder de nuestras obligaciones.
          </li>
          <li>
            <strong>Derecho de limitación.</strong> Usted tiene el derecho a
            solicitar la limitación del tratamiento de tus datos de manera que
            nosotros podamos almacenar tus datos, pero no usarlos.
          </li>
        </ul>
        <p>
          Además de los medios específicos establecidos para cada derecho,
          podrás ejercitar tus derechos de acceso, rectificación, cancelación,
          oposición, limitación y portabilidad, así como revocar los
          consentimientos dados, dirigiéndose por escrito con fotocopia de su
          DNI y con la referencia “Protección de Datos”, a la dirección postal o
          electrónica informada en el encabezado de esta Política.
        </p>
        <p>
          Estos derechos son personalísimos y serán ejercidos por el interesado,
          sin otras limitaciones que las que prevea la legislación aplicable.
          Podrá, no obstante, actuar el representante legal del usuario
          interesado cuando éste se encuentre en situación de incapacidad o
          minoría de edad que le imposibilite el ejercicio personal de los
          mismos. El ejercicio de sus derechos se hará efectivo por el
          Responsable del Tratamiento dentro de treinta días siguientes al de la
          recepción de la solicitud. En el supuesto de que el Responsable del
          Tratamiento considere que no procede acceder a lo solicitado, se lo
          comunicará motivadamente y dentro del plazo señalado en este apartado.
          En los casos en que, siendo procedente la cancelación de los datos, no
          sea posible su extinción física, tanto por razones técnicas como por
          causa del soporte informático utilizado, nosotros procederemos el
          bloqueo de los mismos con el fin de impedir su utilización, hasta que
          su completa eliminación de los sistemas de información.
        </p>

        <h3>Recogida de datos de menores</h3>
        <p>
          La EMPRESA no autoriza a los menores de 14 años a facilitar sus datos
          personales, ya sea mediante la cumplimentación de los formularios web
          habilitados para la solicitud de servicios, los formularios de
          contacto, o mediante el envío de correos electrónicos. Si tenemos
          conocimiento de que un niño menor de 14 años nos proporcionó
          información personal, tomaremos medidas para eliminar esa información.
          Si usted cree que un niño menor de 14 años nos proporcionó información
          personal, escriba al correo info@trileucosolutions.com con los
          detalles y tomaremos las medidas necesarias para eliminar la
          información que tenemos sobre ese niño.
        </p>

        <h3>
          Tratamientos de categorías especiales de datos personales y datos
          personales relativos a condenas e infracciones penales
        </h3>
        <p>
          En la formalización de los campos de texto libre no se permite la
          introducción de información personal relativa a datos personales que
          revelen el origen étnico o racial, las opiniones políticas, las
          convicciones religiosas o filosóficas, o la afiliación sindical, y el
          tratamiento de datos genéticos, datos biométricos dirigidos a
          identificar de manera unívoca a una persona física, datos relativos a
          la salud o datos relativos a la vida sexual o las orientaciones
          sexuales de una persona física, así como datos personales relativos a
          condenas e infracciones penales. En caso de introducir alguna
          información relativa a los aspectos mencionados en cualquiera de
          nuestros formularios o a través del correo electrónico serán
          inmediatamente borrados de nuestros sistemas de información sin poder
          atender la consulta realizada, ya que tales datos no son necesarios ni
          pertinentes para las finalidades determinadas en los tratamientos de
          esta Web.
        </p>

        <h3>Reclamación ante la autoridad de control</h3>
        <p>
          En caso de que entienda que sus derechos han sido desatendidos por
          nuestra entidad, puede formular una reclamación en la Agencia Española
          de Protección de Datos, a través de alguno de los medios siguientes:
        </p>
        <p>
          <strong>Sede electrónica:</strong> www.agpd.es
          <br />
          <strong>Dirección postal:</strong> Agencia Española de Protección de
          Datos C/ Jorge Juan, 6 28001-Madrid
          <br />
          <strong>Vía telefónica:</strong>
          Telf. 901 100 099 Telf. 91 266 35 17
        </p>
        <p>
          Formular una reclamación en la Agencia Española de Protección de Datos
          no conlleva ningún coste y no es necesaria la asistencia de abogado ni
          procurador.
        </p>
        <p>
          Trileuco Solutions, S.L.U.ha adecuado esta web a las exigencias del
          Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de
          abril de 2016 relativo a la protección de las personas físicas (RGPD),
          la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
          Personales y garantía de los derechos digitales (LOPDGDD), así como
          con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
          Información y Comercio Electrónico (LSSICE o LSSI).
        </p>
        <p>
          <strong>© Todos los derechos reservados:</strong> Trileuco Solutions,
          S.L.U.
        </p>
      </BoundedSection>
    </PageWrapper>
  );
};

export default LegalPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
