import * as React from "react";
import * as style from "./boundedSection.module.scss";

const BoundedSection = ({ className, boundedClassName, children }) => {
  return (
    <section
      className={`${style.BoundedSection} ${className ? className : ""}`.trim()}
    >
      <div className={boundedClassName ? boundedClassName : null}>
        {children}
      </div>
    </section>
  );
};

export default BoundedSection;
